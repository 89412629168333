import {useConnectionStore} from "~/stores/connection";


export const createWorkspace = async (name: string, firstEnvName: string) => {
    return useConnectionStore().execute('workspace/create', {
        name: name,
        firstEnvironmentName: firstEnvName,
    })
}

export const deleteWorkspace = async (workspaceId: string) => {
    return useConnectionStore().execute('workspace/delete', {
        workspaceId,
    })
}

export const updateWorkspaceName = async (workspaceId: string, name: string) => {
    return useConnectionStore().execute('workspace/name/update', {
        workspaceId,
        name,
    })

}

export const claimWorkspaceOwnership = async (workspaceId: string, email: string) => {
    return useConnectionStore().execute('workspace/owner/claim', {
        workspaceId, email
    })
}

export const getWorkspaceHistory = async (workspaceId: string, page: number, pageSize: number) => {
    return useConnectionStore().query('workspace/history', {
        workspaceId,
        page,
        pageSize,
    })
}